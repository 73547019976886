import { defineStore } from "pinia";

export const useFloorplan = defineStore("floorplan", {
  state: () => ({
    floorplanList: [],
  }),
  persist: true,
  getters: {
    getFloorplanList(state) {
      return state.floorplanList;
    },
  },
  actions: {
    addFloorplan(data) {
      this.floorplanList = data;
    },
  },
});
