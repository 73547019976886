<template>
  <div
    class="flex flex-col justify-center items-stretch cursor-pointer"
    @click.stop="$emit('doAction', $event)"
    :id="customId"
  >
    <div class="relative">
      <div
        v-if="props.plus"
        class="absolute top-[-5px] left-[-5px] bg-primary-1000 p-1.5 rounded-full"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
        >
          <path
            d="M1 5H9"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            v-if="!active"
            d="M5 1L5 9"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>
      </div>
      <div
        @mouseover="mouseover"
        @mouseleave="mouseleave"
        :class="{
          'qoo-icon-close': props.alwaysClose,
          'qoo-icon-open': props.alwaysOpen,
          'bg-mainBlack-1000': props.bg,
          'bg-mainBlack-600': !props.bg && !upHere,
          'bg-mainBlack-1000': upHere,
        }"
        class="qoo-icon bg-black rounded-[30px] w-[48px] overflow-hidden h-[48px] flex justify-center items-center"
      >
        <img
          :src="require('@/assets/images/' + props.icon)"
          :alt="props.text"
        />
        <p class="">
          {{ props.text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, watch } from "vue";

const upHere = ref(false);

const mouseover = () => {
  if (!props.alwaysClose) {
    setTimeout(() => {
      upHere.value = true;
    }, 500);
  }
};

const mouseleave = () => {
  if (!props.alwaysOpen) upHere.value = false;
};

const props = defineProps({
  icon: {
    type: String,
    default: "floorplans/worksheet.svg",
  },
  dynamicWidth: {
    type: String,
    default: "180px",
  },
  active: {
    type: Boolean,
    default: false,
  },
  text: {
    type: String,
    default: "Add to worksheet",
  },
  bg: {
    type: Boolean,
    default: true,
  },
  plus: {
    type: Boolean,
    default: true,
  },
  alwaysOpen: {
    type: Boolean,
    default: false,
  },
  alwaysClose: {
    type: Boolean,
    default: false,
  },
  customId: {
    type: String,
  },
});

if (props.alwaysOpen) upHere.value = true;

watch(
  () => props.alwaysOpen,
  (newQuestion) => {
    if (newQuestion) {
      upHere.value = true;
    } else {
      upHere.value = false;
    }
  },
);
</script>

<style lang="scss" scoped>
.inactiveIconAnimation {
  .bg-black {
    background-color: #fff !important;
    border: 1px solid #000;
    img {
      filter: brightness(0) saturate(100%) invert(0%) sepia(90%) saturate(7473%)
        hue-rotate(57deg) brightness(89%) contrast(101%);
    }
    p {
      background-color: #000 !important;
    }
  }
}
.qoo-icon {
  transition: 0.5s ease all;
  display: flex;
}

.qoo-icon p {
  transition: 0.8s ease all;
  color: #ffffff00;
  visibility: hidden;
  white-space: nowrap;
  font-size: 0.83rem;
  width: 0 !important;
}

.qoo-icon.qoo-icon-open {
  width: 180px !important;

  p {
    visibility: visible;
    width: auto !important;
    margin-left: 0.5rem;
    // color: #fff !important;
  }
}
@media screen and (min-width: 1700px) {
  .qoo-icon p {
    transition: 0.8s ease all;
    color: #ffffff00;
    visibility: hidden;
    white-space: nowrap;
    font-size: 0.75rem;
    width: 0 !important;
  }
}
@media screen and (min-width: 991px) {
  .qoo-icon:hover {
    width: 180px !important;
  }
  .qoo-icon:hover p {
    visibility: visible;
    width: auto !important;
    margin-left: 0.5rem;
    // color: #fff !important;
  }

  .qoo-icon.qoo-icon-close:hover {
    width: 48px !important;
    p {
      visibility: hidden !important;
      width: 0 !important;
      margin-left: 0rem;
    }
  }
}
</style>
