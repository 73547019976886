<template>
  <div class="content">
    <h5 :style="{ color: textColor }">
      {{ toastProps?.data.message }}
    </h5>
    <div class="divider"></div>
    <button
      @click="
        ($event) => {
          closeToast && closeToast($event);
        }
      "
    >
      <i
        style="font-size: 18px; color: rgb(255 255 255 / 0.5)"
        class="iconsax"
        icon-name="x"
      ></i>
    </button>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  closeToast: Function,
  toastProps: Object,
});

const textColor = computed(() => {
  switch (props.toastProps?.type) {
    case "success":
      return "rgba(41, 204, 167, 1)";
    case "warning":
      return "rgba(255, 166, 34, 1)";
    case "error":
      return "rgba(255, 82, 82, 1)";
    default:
      return "rgb(255 255 255)";
  }
});
</script>

<style lang="scss">
:root {
  --toastify-color-light: #fff;
  --toastify-color-success: rgba(41, 204, 167, 1);
  --toastify-color-warning: rgba(255, 166, 34, 1);
  --toastify-color-error: rgba(255, 82, 82, 1);
  --toastify-text-color-success: rgba(41, 204, 167, 1);
  --toastify-text-color-warning: rgba(255, 166, 34, 1);
  --toastify-text-color-error: rgba(255, 82, 82, 1);
  --toastify-toast-min-height: 50px;
}

.app-toast {
  min-width: 300px;
  & > div {
    border-radius: 12px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
    font-size: 12px;
    font-weight: 600;
    font-family: "Nunito", sans-serif;
    cursor: default;
    margin-bottom: 0px;
    padding: 0 16px;
    width: fit-content;
  }

  .content {
    display: flex;
    align-items: center;
    h5 {
      flex-grow: 1;
      width: max-content;
    }
    .undo {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      width: 72px;
      height: 34px;
      background-color: rgba(255 255 255 / 0.1);
      flex-shrink: 0;
      border-radius: 6px;
      margin-left: 8px;
    }
    .divider {
      width: 0px;
      height: 22px;
      border-right: 1px solid rgb(255 255 255 / 0.2);
      margin: 0 20px 0 12px;
    }
  }
}
</style>
