<template>
  <div
    class="grow-0 transition-[height] duration-500"
    :class="
      openMenu && menuDirection === 'vertical' ? 'h-0 overflow-y-hidden' : 'h-'
    "
  >
    <div
      class="bg-white flex flex-col pt-[1.125rem] w-full max-md:max-w-full panel-main-header"
    >
      <div
        class="flex items-stretch justify-between gap-5 mx-4 md:mx-9 max-md:max-w-full max-md:flex-wrap mr-4 max-md:mr-2.5"
      >
        <div class="flex items-stretch justify-between gap-5 w-full md:w-fit">
          <button
            @click="toggleSidebar()"
            class="cursor-pointer menu-container"
          >
            <img src="@/assets/icons/svg/menu.svg" />
          </button>
          <a href="/" class="flex items-center">
            <img
              src="@/assets/images/header/logo-horizontal.svg"
              loading="lazy"
              class="aspect-[2.13] object-contain object-center w-[102px] overflow-hidden self-center shrink-0 max-w-full my-auto"
            />
          </a>
        </div>
        <div
          class="hidden md:flex items-stretch justify-between gap-3 max-md:max-w-full max-md:flex-wrap"
        >
          <qooBtn
            type="secondary"
            icon="star2.svg"
            text="Favourites"
            width="w-5"
            @clickBtn="favouritesPage"
          ></qooBtn>
          <qooBtn
            v-if="+showWorksheetRoute"
            icon="worksheet.svg"
            text="Submit Worksheet"
            :hasCounter="true"
            :counterNumber="store.worksheetId?.length"
            @clickBtn="worksheet"
          ></qooBtn>
        </div>
      </div>
      <div
        class="qoo-line min-h-[1px] w-full mt-[1.0625rem] max-md:max-w-full"
        :class="openMenu ? '' : 'bg-menu-color'"
      ></div>
    </div>
    <MainMenuHorizontal
      v-if="menuDirection === 'horizontal'"
      class="transition-transform duration-500"
      :class="openMenu ? 'translate-y-0' : '-translate-y-[140vh]'"
      @closeSidebar="closeSidebar"
    />
    <MainMenuVertical
      v-else-if="menuDirection === 'vertical'"
      class="transition-transform duration-500"
      :class="openMenu ? 'translate-x-0' : '-translate-x-[100vw]'"
      @closeSidebar="closeSidebar"
    />
  </div>
</template>

<script setup>
import { ref, watchEffect, onMounted, watch } from "vue";
import MainMenuHorizontal from "./MainMenuHorizontal.vue";
import MainMenuVertical from "./MainMenuVertical.vue";
import qooBtn from "./common/qooBtn.vue";
import { useRouter } from "vue-router";
import { useWorksheets } from "@/store/worksheet.js";
import { useVerticalMenu } from "@/store/verticalMenu";

const menuDirection = process.env.VUE_APP_MENU_DIRECTION;
const showWorksheetRoute = process.env.VUE_APP_SHOW_WORKSHEET;

const router = useRouter();
const openMenu = ref(false);

const toggleSidebar = () => {
  openMenu.value = !openMenu.value;

  // if (openMenu.value) {
  //   document.getElementsByTagName("html")[0].style.overflow = "hidden";
  // } else {
  //   document.getElementsByTagName("html")[0].style.overflow = "auto";
  // }
};

const closeSidebar = () => {
  openMenu.value = false;
};

const favouritesPage = () => {
  if (openMenu.value) {
    toggleSidebar();
  }
  router.push({ name: "favouritesPage" });
};

const worksheet = () => {
  if (openMenu.value) {
    toggleSidebar();
  }
  router.push({ name: "worksheet" });
};

let apiFrameEl = null;
const store = ref({});

onMounted(() => {
  apiFrameEl = document.getElementById("api-frame");
  store.value = useWorksheets();
});

const verticalMenuStore = useVerticalMenu();

watch(
  () => openMenu.value,
  () => {
    if (menuDirection === "vertical") {
      verticalMenuStore.setIsOpen(openMenu.value);
    }
    if (apiFrameEl && menuDirection === "vertical" && openMenu.value) {
      apiFrameEl.style.height = "calc(100vh - 0rem)";
    } else {
      apiFrameEl.style.height = "calc(100vh - 6rem)";
    }
  },
);
</script>

<style lang="scss">
@import "MainHeader.scss";

.qoo-line {
  transition: 1.2s ease all;
}

$menu-width: 24px;
$menu-height: 24px;

.menu-container {
  border-radius: 30px;
  // border: 1px solid #000;
  background: rgba(217, 217, 217, 0);
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  & .menu {
    width: $menu-width;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;

    & .menu-item {
      transition: 0.5s ease all;
      display: block;
      width: $menu-width;
      height: 2.5px;
      box-sizing: border-box;
      border-radius: 5px;
      background: #000000;
    }

    &.open {
      .menu-item {
        // background: #000000;
        width: 26px;

        &:first-child {
          transform: rotate(45deg);
          transform-origin: 6px 5px;
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
          transform-origin: 5px -2px;
        }
      }
    }
  }
}

.bg-menu-color {
  background-color: #b3b3b3;
  opacity: 1;
}
</style>
