<template>
  <div
    class="wrapper block relative w-full"
    id="image-sequence"
    :class="{ 'mobile-scale': mobileScale, 'show-opacity': svgTopNumber }"
  >
    <div class="container-image" :style="{ top: `${svgTopNumber}px` }">
      <img
        :src="imageSource"
        @mousedown="handleMouseDown"
        @mousemove="handleMouseMove"
        @mouseup="handleMouseUp"
        @touchstart="handleMouseDown"
        @touchmove="handleMouseMove"
        @touchend="handleMouseUp"
        class="w-full"
        :class="isDragging ? 'cursor-grabbing' : 'cursor-grab'"
        ref="seqBgImage"
      />
      <InlineSvg
        :src="svgPath"
        class="svg pointer-events-none absolute inset-0 w-full h-full transition-all z-10 [&>image]:pointer-events-auto [&>image]:cursor-pointer [&>image]:transition-all"
        :class="
          isDragging ? 'cursor-grabbing opacity-0' : 'cursor-grab opacity-100'
        "
        @loaded="setEventListenersToSvgImages"
      />
    </div>
    <div
      class="bg-black/80 box-modal text-white rounded p-2 fixed z-20 transition-opacity"
      :class="isInfoBoxVisible ? 'visible opacity-100' : 'invisible opacity-0'"
      :style="{
        top: `${y - 100}px`,
        left: `${x + 10}px`,
      }"
    >
      <div class="box-modal-header border-b flex items-center">
        <h4 class="text-base text-white mr-2">{{ hoverdUntiDetail.name }}</h4>
        <p class="text-sm text-white">
          <span class="mr-1">|</span> {{ hoverdUntiDetail.model_name }}
        </p>
      </div>
      <div class="box-modal-body">
        <ul class="flex flex-wrap items-between">
          <li class="w-1/2 mb-2">
            <span class="title text-xs text-white opacity-40 block">Type</span>
            <span class="text-sm text-white">{{
              hoverdUntiDetail.suite_style || "-"
            }}</span>
          </li>
          <li class="w-1/2 mb-2">
            <span class="title text-xs text-white opacity-40 block">Area</span>
            <span class="text-sm text-white">{{
              hoverdUntiDetail.area ? hoverdUntiDetail.area + " sq.ft." : "-"
            }}</span>
          </li>
          <li class="w-1/2 mb-2">
            <span class="title text-xs text-white opacity-40 block"
              >Bathrooms</span
            >
            <span class="text-sm text-white">{{
              hoverdUntiDetail.bathroom || "-"
            }}</span>
          </li>
          <li class="w-1/2 mb-2">
            <span class="title text-xs text-white opacity-40 block"
              >Orientation</span
            >
            <span class="text-sm text-white">{{
              hoverdUntiDetail.exposure || "-"
            }}</span>
          </li>
          <li class="w-1/2">
            <span class="title text-xs text-white opacity-40 block">Floor</span>
            <span class="text-sm text-white">{{
              hoverdUntiDetail.floor?.name || "-"
            }}</span>
          </li>
          <li class="w-1/2">
            <span class="title text-xs text-white opacity-40 block">Price</span>
            <span class="text-sm text-white">{{
              hoverdUntiDetail.price
                ? "$" + hoverdUntiDetail.price.toLocaleString("en-US")
                : "-"
            }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="operations flex items-center absolute bottom-10">
    <span class="ml-3">
      <iconAnimation
        :plus="false"
        icon="floorplans/show-suites.svg"
        @click="toggleHighlights()"
        :text="showHighlights ? 'Hide Suites' : 'Show Suites'"
      ></iconAnimation>
    </span>
    <span class="ml-3">
      <iconAnimation
        v-if="mode !== 'both'"
        :plus="false"
        icon="floorplans/floorplans.svg"
        :text="mode !== 'both' ? 'Floorplans' : 'Building'"
        @click="$emit('goToBothView')"
      ></iconAnimation>
      <iconAnimation
        v-else
        :plus="false"
        icon="floorplans/floorplans.svg"
        text="Building"
        @click="$emit('backToModel3d')"
      ></iconAnimation>
    </span>
  </div>

  <popup-modal
    w="624px"
    classList="p-5 sm:p-6 md:py-9 md:px-6"
    :title="selectedImageForModal.title"
    @close="closeImageModal($event)"
    v-show="isShowImageModal"
  >
    <div class="w-full relative">
      <img
        :src="selectedImageForModal.url"
        alt="image"
        class="max-h-96 mx-auto"
      />
      <FullScreen
        @click="showFullScreen = true"
        class="absolute bottom-2 right-2 cursor-pointer"
      ></FullScreen>
    </div>
    <div
      v-if="showFullScreen"
      class="fixed top-0 left-0 w-full z-[250] h-[100vh]"
    >
      <div class="w-full relative h-[100vh]">
        <img :src="selectedImageForModal.url" alt="image" class="mx-auto" />
        <button
          @click="showFullScreen = false"
          class="absolute drop-shadow-[0_4px_3px_rgba(0,0,0,0.25)] top-3 right-3 cursor-pointer text-xl"
        >
          ✖
        </button>
      </div>
    </div>
  </popup-modal>

  <OverlayLoader v-show="isLoading" />
</template>

<script setup>
import { computed, ref, watch, onMounted, watchEffect } from "vue";
import { useMouse } from "@vueuse/core";
import { useModels } from "@/store/models.js";
import { pointers } from "@/services/axios/pointers.service.js";

import OverlayLoader from "@/components/base/overlay/OverlayLoader.vue";
import FullScreen from "@/components/svg/FullScreen.vue";
import PopupModal from "@/components/base/PopupModalAnimation.vue";
import InlineSvg from "vue-inline-svg";
import iconAnimation from "@/components/base/common/iconAnimation.vue";

const props = defineProps({
  numberOfImages: Number,
  imageType: String,
  pageShowStatus: Number,
  model: Object,
  mode: String,
});

const baseName = process.env.VUE_APP_SEQUENCE_BASE_NAME;
const tvFolderName = process.env.VUE_APP_SEQUENCE_TV;
const desktopFolderName = process.env.VUE_APP_SEQUENCE_DESKTOP;
const mobileFolderName = process.env.VUE_APP_SEQUENCE_MOBILE;
const mobileScale = process.env.VUE_APP_SEQUENCE_RESPONSIVE_ZOOM;

const emits = defineEmits(["clickedUnit", "goToBothView", "backToModel3d"]);

const isLoading = ref(false);

const pointersArray = ref([]);
const isShowImageModal = ref(false);
const showFullScreen = ref(false);
const selectedImageForModal = ref({
  url: "",
  title: "",
});

const pageHeight = ref(window.innerHeight);
const svgTopNumber = ref("");
const seqBgImage = ref(0);

const imageNumber = ref(0);
const oldX = ref(0);
const images = ref([]);
const isDragging = ref(false);
const isInfoBoxVisible = ref(false);
const svgPath = ref("");
const { x, y } = useMouse();

const currentAngle = ref(0);
const lastAngle = ref(0);

const allData = ref([]);
const allUnits = ref([]);
const allUnitsName = ref([]);
const hoverdUntiDetail = ref("");
const showHighlights = ref(true);

const modelsStore = useModels();

const imageSource = computed(() => {
  return images.value[imageNumber.value]?.src;
});

const modelsList = computed(() => {
  return modelsStore.getModelsFilteredList;
});

function padWithZeros(number, length) {
  return number?.toString()?.padStart(length, "0");
}

function getWidth() {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth,
  );
}

function prefetchImages() {
  let folder = "";
  isLoading.value = true;
  if (getWidth() >= 2500) {
    folder = tvFolderName;
  } else if (getWidth() > 768 && getWidth() < 2500) {
    folder = desktopFolderName;
  } else {
    folder = mobileFolderName;
  }

  for (let i = 0; i < props.numberOfImages; i++) {
    const img = new Image();
    const svg = new Image();
    img.src = `${folder}/images/${baseName}.${padWithZeros(i, 4)}.${
      props.imageType
    }`;
    svg.src = `${folder}/svgs/${baseName}.${padWithZeros(i, 4)}.svg`;
    img.onload = function () {
      if (i == 120) {
        isLoading.value = false;
      }
    };
    images.value.push(img);
  }
}

function handleMouseMove(event) {
  event.preventDefault();
  if (!isDragging.value) return;
  const mouseX = event.clientX || event.touches[0]?.clientX;
  const delta = mouseX - lastAngle.value;
  currentAngle.value += delta;
  lastAngle.value = mouseX;

  const direction =
    mouseX > oldX.value ? "left" : mouseX < oldX.value ? "right" : null;

  if (direction === "right") {
    imageNumber.value = imageNumber.value - 1;
    if (imageNumber.value < 0) {
      imageNumber.value = props.numberOfImages - 1;
    }
  }
  if (direction === "left") {
    imageNumber.value = imageNumber.value + 1;
    if (imageNumber.value >= props.numberOfImages) {
      imageNumber.value = 0;
    }
  }

  oldX.value = mouseX;
}

function handleMouseDown(event) {
  event.preventDefault();
  isDragging.value = true;
  removeUnitsColor();
  lastAngle.value = event.clientX || event.touches[0]?.clientX;
}

function handleMouseUp() {
  isDragging.value = false;
  console.log("MouseUp");
  setSvgPath();
}

function setSvgPath() {
  let folder = "";
  if (getWidth() >= 2500) {
    folder = tvFolderName;
  } else if (getWidth() > 768 && getWidth() < 2500) {
    folder = desktopFolderName;
  } else {
    folder = mobileFolderName;
  }

  console.log("svgPath Set");
  svgPath.value = `/${folder}/svgs/${baseName}.${padWithZeros(
    imageNumber.value,
    4,
  )}.svg`;
}

function toggleHighlights() {
  if (showHighlights.value) {
    removeUnitsColor();
    showHighlights.value = false;
  } else {
    setEventListenersToSvgImages();
    showHighlights.value = true;
  }
}

function setEventListenersToSvgImages() {
  resetUnits();

  const unitEls = document.querySelectorAll("svg > [id^='Unit_']");
  const pointerEls = document.querySelectorAll("svg > [id^='Pointer_']");

  pointerEls.forEach((element) => {
    element.classList.add("opacity-0");
    element.after(createCircleSvg(element));
    element.addEventListener("click", (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();
      const index = e.target.id.split("Pointer_")[1];
      if (index >= 0) {
        const item = pointersArray.value.find((x) => x.identifier == index);
        if (item) {
          selectedImageForModal.value = {
            url: item.img,
            title: item.title,
          };
          openImageModal();
        }
      }
    });
  });

  unitEls.forEach((unitEl) => {
    setUnits(unitEl);
  });

  const h = Math.max(seqBgImage.value?.clientHeight, window.innerHeight || 0);
  svgTopNumber.value = (pageHeight.value - h) / 2;
}

function closeImageModal() {
  isShowImageModal.value = false;
}

function openImageModal() {
  isShowImageModal.value = true;
}

function createCircleSvg(el) {
  const svgNS = "http://www.w3.org/2000/svg";

  const svg = document.createElementNS(svgNS, "svg");
  svg.setAttribute("width", "45");
  svg.setAttribute("height", "45");
  svg.setAttribute("viewBox", "0 0 90 90");
  svg.setAttribute("x", el.getAttribute("x"));
  svg.setAttribute("y", el.getAttribute("y"));
  svg.setAttribute("id", el.getAttribute("id"));
  svg.classList.add("cursor-pointer");

  const circle1 = document.createElementNS(svgNS, "circle");
  circle1.setAttribute("class", "ring");
  circle1.setAttribute("r", "45");
  circle1.setAttribute("cx", "45");
  circle1.setAttribute("cy", "45");
  circle1.setAttribute("fill", "#00000");

  const circle2 = document.createElementNS(svgNS, "circle");
  circle2.setAttribute("class", "dot");
  circle2.setAttribute("r", "12");
  circle2.setAttribute("cx", "45");
  circle2.setAttribute("cy", "45");
  circle2.setAttribute("fill", "white");

  svg.appendChild(circle1);
  svg.appendChild(circle2);

  return svg;
}

// data and filter
function getAllUnitsWithParentData(data) {
  const result = [];

  data.forEach((parent) => {
    parent.units.forEach((unit) => {
      const combinedUnit = {
        ...parent,
        ...unit,
      };
      delete combinedUnit.units;
      result.push(combinedUnit);
    });
  });
  return result;
}

function setData(data) {
  allUnits.value = getAllUnitsWithParentData(data);
  console.log(allUnits.value);
  allUnitsName.value = allUnits.value.map(({ box_name, status }) => {
    if (status === "Available") {
      return box_name;
    }
  });

  setEventListenersToSvgImages();
}

function setUnits(node) {
  const nodeIsUnit = node.id.indexOf("Unit_");
  const nodeName = node.id.split("Unit_");

  if (nodeIsUnit >= 0 && allUnitsName.value.indexOf(nodeName[1]) >= 0) {
    node.classList.remove("green");
    node.classList.add("green");

    node.addEventListener("click", (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();
      const unitDetail =
        allUnits.value.find((x) => x.box_name === nodeName[1]) || "";

      if (unitDetail) {
        emits("clickedUnit", unitDetail.model_name);
      }
    });
    node.addEventListener("mouseover", () => {
      hoverdUntiDetail.value =
        allUnits.value.find((x) => x.box_name === nodeName[1]) || "";
      isInfoBoxVisible.value = true;
    });
    node.addEventListener("mouseout", () => {
      isInfoBoxVisible.value = false;
      hoverdUntiDetail.value = "";
    });
  } else {
    node.classList.add("hide");
  }
}

function resetUnits() {
  const unitEls = document.querySelectorAll("svg > [id^='Unit_']");
  const pointerEls = document.querySelectorAll("svg[id^='Pointer_']");

  pointerEls.forEach((pointer) => {
    pointer.remove();
  });

  unitEls.forEach((unitEl) => {
    unitEl.classList.remove("hide");
    unitEl.classList.remove("green");
  });
}

function removeUnitsColor() {
  const unitEls = document.querySelectorAll("svg > [id^='Unit_']");

  unitEls.forEach((unitEl) => {
    unitEl.classList.remove("green");
    unitEl.classList.add("hide");
  });
}

watchEffect(() => {
  const handleResize = () => {
    pageHeight.value = window.innerHeight;
    const imageHeight = Math.max(
      seqBgImage.value?.clientHeight,
      window.innerHeight || 0,
    );
    svgTopNumber.value = (pageHeight.value - imageHeight) / 2;
  };

  window.addEventListener("resize", handleResize);

  // Cleanup function
  return () => {
    window.removeEventListener("resize", handleResize);
  };
});

onMounted(async () => {
  const p = await pointers();
  pointersArray.value = p.data;

  prefetchImages();
  const data = [
    {
      id: 126,
      area: 1078,
      color: null,
      indoor_area: 980,
      outdoor_area: 98,
      initial_price: 120000,
      model_name: "test may17",
      suite_style: "2 Bed",
      increase_per_floor: null,
      exposure: ["S", "W"],
      bedroom: 2,
      bathroom: 2,
      den: false,
      study: false,
      tour_360: "https://temp.qoo.studio/#/floorplans?full_3d=false",
      order: 0,
      floors: [],
      units: [],
      floor_plan: {
        id: 509,
        title: "test may17",
        order: 1,
        image: "https://qoobox.qoo.studio/storage/uploads/664753e15ba7f_1a.png",
        thumbnail: null,
        file: null,
        link: null,
        for: "UnitModel",
      },
      key_plan: {
        id: 510,
        title: "test may17",
        order: 1,
        image: "https://qoobox.qoo.studio/storage/uploads/664753e70543d_1a.png",
        thumbnail: null,
        file: null,
        link: null,
        for: "UnitModel",
      },
      document: {
        id: 511,
        title: "test may17",
        order: 1,
        image: null,
        thumbnail: null,
        file: "https://qoobox.qoo.studio/storage/uploads/664753eac4e76_1A.pdf",
        link: null,
        for: "UnitModel",
      },
      gallery: [
        {
          id: 78,
          type: "floorplan",
          name: "floorplan",
          order: 1,
          category_item: [
            {
              id: 84,
              name: "Floor Plan",
              order: 1,
              galleries: [
                {
                  id: 506,
                  title: "model test 124",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/66471a53bc46a_494-G.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 509,
                  title: "test may17",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/664753e15ba7f_1a.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 518,
                  title: "test 3 may 17",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/66476e2ec1e90_1c+d.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 521,
                  title: "test 4 may17",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/66476e8be26b1_1d+d.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
              ],
            },
          ],
        },
        {
          id: 79,
          type: "keyplan",
          name: "keyplan",
          order: 1,
          category_item: [
            {
              id: 85,
              name: "Key Plan",
              order: 1,
              galleries: [
                {
                  id: 507,
                  title: "model test 124",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/66471a589877c_494-g.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 510,
                  title: "test may17",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/664753e70543d_1a.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 519,
                  title: "test 3 may 17",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/66476e32abe07_1c+d.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 522,
                  title: "test 4 may17",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/66476ea02e0cb_1d+d.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
              ],
            },
          ],
        },
        {
          id: 80,
          type: "document",
          name: "document",
          order: 1,
          category_item: [
            {
              id: 86,
              name: "Document",
              order: 1,
              galleries: [
                {
                  id: 508,
                  title: "model test 124",
                  order: 1,
                  image: null,
                  thumbnail: null,
                  file: "https://qoobox.qoo.studio/storage/uploads/66471a8520284_video brochure-cover-02_Apr22.pdf",
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 511,
                  title: "test may17",
                  order: 1,
                  image: null,
                  thumbnail: null,
                  file: "https://qoobox.qoo.studio/storage/uploads/664753eac4e76_1A.pdf",
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 520,
                  title: "test 3 may 17",
                  order: 1,
                  image: null,
                  thumbnail: null,
                  file: "https://qoobox.qoo.studio/storage/uploads/66476e36eea93_1C+D.pdf",
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 523,
                  title: "test 4 may17",
                  order: 1,
                  image: null,
                  thumbnail: null,
                  file: "https://qoobox.qoo.studio/storage/uploads/66476ea4dd446_1D+D.pdf",
                  link: null,
                  for: "UnitModel",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 128,
      area: 1600,
      color: null,
      indoor_area: 1400,
      outdoor_area: 200,
      initial_price: null,
      model_name: "test 3 may 17",
      suite_style: "3 Bed + Den",
      increase_per_floor: null,
      exposure: ["N", "E"],
      bedroom: 3,
      bathroom: 3,
      den: true,
      study: false,
      tour_360: null,
      order: 0,
      floors: [5],
      units: [],
      floor_plan: {
        id: 518,
        title: "test 3 may 17",
        order: 1,
        image:
          "https://qoobox.qoo.studio/storage/uploads/66476e2ec1e90_1c+d.png",
        thumbnail: null,
        file: null,
        link: null,
        for: "UnitModel",
      },
      key_plan: {
        id: 519,
        title: "test 3 may 17",
        order: 1,
        image:
          "https://qoobox.qoo.studio/storage/uploads/66476e32abe07_1c+d.png",
        thumbnail: null,
        file: null,
        link: null,
        for: "UnitModel",
      },
      document: {
        id: 520,
        title: "test 3 may 17",
        order: 1,
        image: null,
        thumbnail: null,
        file: "https://qoobox.qoo.studio/storage/uploads/66476e36eea93_1C+D.pdf",
        link: null,
        for: "UnitModel",
      },
      gallery: [
        {
          id: 78,
          type: "floorplan",
          name: "floorplan",
          order: 1,
          category_item: [
            {
              id: 84,
              name: "Floor Plan",
              order: 1,
              galleries: [
                {
                  id: 506,
                  title: "model test 124",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/66471a53bc46a_494-G.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 509,
                  title: "test may17",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/664753e15ba7f_1a.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 518,
                  title: "test 3 may 17",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/66476e2ec1e90_1c+d.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 521,
                  title: "test 4 may17",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/66476e8be26b1_1d+d.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
              ],
            },
          ],
        },
        {
          id: 79,
          type: "keyplan",
          name: "keyplan",
          order: 1,
          category_item: [
            {
              id: 85,
              name: "Key Plan",
              order: 1,
              galleries: [
                {
                  id: 507,
                  title: "model test 124",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/66471a589877c_494-g.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 510,
                  title: "test may17",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/664753e70543d_1a.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 519,
                  title: "test 3 may 17",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/66476e32abe07_1c+d.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 522,
                  title: "test 4 may17",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/66476ea02e0cb_1d+d.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
              ],
            },
          ],
        },
        {
          id: 80,
          type: "document",
          name: "document",
          order: 1,
          category_item: [
            {
              id: 86,
              name: "Document",
              order: 1,
              galleries: [
                {
                  id: 508,
                  title: "model test 124",
                  order: 1,
                  image: null,
                  thumbnail: null,
                  file: "https://qoobox.qoo.studio/storage/uploads/66471a8520284_video brochure-cover-02_Apr22.pdf",
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 511,
                  title: "test may17",
                  order: 1,
                  image: null,
                  thumbnail: null,
                  file: "https://qoobox.qoo.studio/storage/uploads/664753eac4e76_1A.pdf",
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 520,
                  title: "test 3 may 17",
                  order: 1,
                  image: null,
                  thumbnail: null,
                  file: "https://qoobox.qoo.studio/storage/uploads/66476e36eea93_1C+D.pdf",
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 523,
                  title: "test 4 may17",
                  order: 1,
                  image: null,
                  thumbnail: null,
                  file: "https://qoobox.qoo.studio/storage/uploads/66476ea4dd446_1D+D.pdf",
                  link: null,
                  for: "UnitModel",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 121,
      area: 1354,
      color: null,
      indoor_area: 1234,
      outdoor_area: 120,
      initial_price: null,
      model_name: "test may 13",
      suite_style: "2 Bed",
      increase_per_floor: null,
      exposure: ["N", "E"],
      bedroom: 2,
      bathroom: 3,
      den: true,
      study: false,
      tour_360: null,
      order: 2,
      floors: [2, 8, 2],
      units: [
        {
          id: 230,
          name: "201",
          plan_name: null,
          status: "Available",
          legal_unit: null,
          price: 230000,
          parking: 0,
          study: null,
          locker: 0,
          description: null,
          box_name: "201",
          building: "1",
          exposure: "W",
          floor_name: 2,
          floor: {
            id: 35,
            name: "2",
            order: 2,
            plan: null,
          },
          gallery: [],
          balcony: null,
        },
        {
          id: 251,
          name: "210",
          plan_name: null,
          status: "Available",
          legal_unit: null,
          price: null,
          parking: 0,
          study: null,
          locker: 0,
          description: null,
          box_name: "210",
          building: "1",
          exposure: "W",
          floor_name: 2,
          floor: {
            id: 35,
            name: "2",
            order: 2,
            plan: null,
          },
          gallery: [],
          balcony: null,
        },
        {
          id: 255,
          name: "103",
          plan_name: null,
          status: "Available",
          legal_unit: null,
          price: null,
          parking: 0,
          study: null,
          locker: 0,
          description: null,
          box_name: "103",
          building: "1",
          exposure: "W",
          floor_name: 2,
          floor: {
            id: 54,
            name: "1",
            order: 2,
            plan: null,
          },
          gallery: [],
          balcony: null,
        },
        {
          id: 247,
          name: "2122",
          plan_name: null,
          status: "Available",
          legal_unit: null,
          price: 1230000,
          parking: 1,
          study: null,
          locker: 1,
          description: null,
          box_name: "2122",
          building: "1",
          exposure: "W",
          floor_name: 8,
          floor: {
            id: 41,
            name: "8",
            order: 8,
            plan: null,
          },
          gallery: [],
          balcony: null,
        },
      ],
      floor_plan: null,
      key_plan: null,
      document: null,
      gallery: [],
    },
    {
      id: 124,
      area: 1222,
      color: null,
      indoor_area: 1222,
      outdoor_area: 0,
      initial_price: null,
      model_name: "model test 124",
      suite_style: "1 Bed + Den",
      increase_per_floor: null,
      exposure: ["N", "E"],
      bedroom: 1,
      bathroom: 1,
      den: true,
      study: false,
      tour_360: null,
      order: 2,
      floors: [4, 2],
      units: [
        {
          id: 253,
          name: "909230",
          plan_name: null,
          status: "Available",
          legal_unit: null,
          price: 900000,
          parking: 0,
          study: null,
          locker: 0,
          description: null,
          box_name: "909230",
          building: "1",
          exposure: "W",
          floor_name: 4,
          floor: {
            id: 37,
            name: "4",
            order: 4,
            plan: null,
          },
          gallery: [],
          balcony: null,
        },
        {
          id: 260,
          name: "201",
          plan_name: null,
          status: "Available",
          legal_unit: null,
          price: null,
          parking: 0,
          study: null,
          locker: 0,
          description: null,
          box_name: "201",
          building: "1",
          exposure: "W",
          floor_name: 2,
          floor: {
            id: 35,
            name: "2",
            order: 2,
            plan: null,
          },
          gallery: [],
          balcony: null,
        },
      ],
      floor_plan: {
        id: 506,
        title: "model test 124",
        order: 1,
        image:
          "https://qoobox.qoo.studio/storage/uploads/66471a53bc46a_494-G.png",
        thumbnail: null,
        file: null,
        link: null,
        for: "UnitModel",
      },
      key_plan: {
        id: 507,
        title: "model test 124",
        order: 1,
        image:
          "https://qoobox.qoo.studio/storage/uploads/66471a589877c_494-g.png",
        thumbnail: null,
        file: null,
        link: null,
        for: "UnitModel",
      },
      document: {
        id: 508,
        title: "model test 124",
        order: 1,
        image: null,
        thumbnail: null,
        file: "https://qoobox.qoo.studio/storage/uploads/66471a8520284_video brochure-cover-02_Apr22.pdf",
        link: null,
        for: "UnitModel",
      },
      gallery: [
        {
          id: 78,
          type: "floorplan",
          name: "floorplan",
          order: 1,
          category_item: [
            {
              id: 84,
              name: "Floor Plan",
              order: 1,
              galleries: [
                {
                  id: 506,
                  title: "model test 124",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/66471a53bc46a_494-G.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 509,
                  title: "test may17",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/664753e15ba7f_1a.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 518,
                  title: "test 3 may 17",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/66476e2ec1e90_1c+d.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 521,
                  title: "test 4 may17",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/66476e8be26b1_1d+d.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
              ],
            },
          ],
        },
        {
          id: 79,
          type: "keyplan",
          name: "keyplan",
          order: 1,
          category_item: [
            {
              id: 85,
              name: "Key Plan",
              order: 1,
              galleries: [
                {
                  id: 507,
                  title: "model test 124",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/66471a589877c_494-g.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 510,
                  title: "test may17",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/664753e70543d_1a.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 519,
                  title: "test 3 may 17",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/66476e32abe07_1c+d.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 522,
                  title: "test 4 may17",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/66476ea02e0cb_1d+d.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
              ],
            },
          ],
        },
        {
          id: 80,
          type: "document",
          name: "document",
          order: 1,
          category_item: [
            {
              id: 86,
              name: "Document",
              order: 1,
              galleries: [
                {
                  id: 508,
                  title: "model test 124",
                  order: 1,
                  image: null,
                  thumbnail: null,
                  file: "https://qoobox.qoo.studio/storage/uploads/66471a8520284_video brochure-cover-02_Apr22.pdf",
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 511,
                  title: "test may17",
                  order: 1,
                  image: null,
                  thumbnail: null,
                  file: "https://qoobox.qoo.studio/storage/uploads/664753eac4e76_1A.pdf",
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 520,
                  title: "test 3 may 17",
                  order: 1,
                  image: null,
                  thumbnail: null,
                  file: "https://qoobox.qoo.studio/storage/uploads/66476e36eea93_1C+D.pdf",
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 523,
                  title: "test 4 may17",
                  order: 1,
                  image: null,
                  thumbnail: null,
                  file: "https://qoobox.qoo.studio/storage/uploads/66476ea4dd446_1D+D.pdf",
                  link: null,
                  for: "UnitModel",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 100,
      area: 1400,
      color: null,
      indoor_area: 1190,
      outdoor_area: 210,
      initial_price: 910000,
      model_name: "Model test 3",
      suite_style: "3 Bed",
      increase_per_floor: null,
      exposure: ["N"],
      bedroom: 3,
      bathroom: 3,
      den: false,
      study: false,
      tour_360: null,
      order: 3,
      floors: [3, 6],
      units: [
        {
          id: 236,
          name: "303",
          plan_name: null,
          status: "Available",
          legal_unit: null,
          price: 142800,
          parking: 0,
          study: null,
          locker: 0,
          description: null,
          box_name: "303",
          building: "1",
          exposure: "W",
          floor_name: 3,
          floor: {
            id: 36,
            name: "3",
            order: 3,
            plan: null,
          },
          gallery: [],
          balcony: null,
        },
      ],
      floor_plan: null,
      key_plan: null,
      document: null,
      gallery: [],
    },
    {
      id: 122,
      area: 134,
      color: null,
      indoor_area: 122,
      outdoor_area: 12,
      initial_price: null,
      model_name: "test model",
      suite_style: "3 Bed",
      increase_per_floor: null,
      exposure: ["S", "W"],
      bedroom: 3,
      bathroom: 2,
      den: false,
      study: false,
      tour_360: null,
      order: 3,
      floors: [],
      units: [],
      floor_plan: null,
      key_plan: null,
      document: null,
      gallery: [],
    },
    {
      id: 115,
      area: 20,
      color: null,
      indoor_area: 10,
      outdoor_area: 10,
      initial_price: 170000,
      model_name: "Model test 1",
      suite_style: "1 Bed + Den",
      increase_per_floor: null,
      exposure: ["N"],
      bedroom: 3,
      bathroom: 2,
      den: true,
      study: true,
      tour_360: null,
      order: 4,
      floors: [9, 4],
      units: [
        {
          id: 234,
          name: "901",
          plan_name: null,
          status: "Available",
          legal_unit: null,
          price: 690000,
          parking: 1,
          study: null,
          locker: 0,
          description: null,
          box_name: "901",
          building: "1",
          exposure: "W",
          floor_name: 9,
          floor: {
            id: 42,
            name: "9",
            order: 9,
            plan: null,
          },
          gallery: [],
          balcony: null,
        },
        {
          id: 241,
          name: "408",
          plan_name: null,
          status: "Available",
          legal_unit: null,
          price: 0,
          parking: 0,
          study: null,
          locker: 0,
          description: null,
          box_name: "408",
          building: "1",
          exposure: "W",
          floor_name: 4,
          floor: {
            id: 37,
            name: "4",
            order: 4,
            plan: null,
          },
          gallery: [],
          balcony: null,
        },
      ],
      floor_plan: null,
      key_plan: null,
      document: null,
      gallery: [],
    },
    {
      id: 129,
      area: 1700,
      color: null,
      indoor_area: 1500,
      outdoor_area: 200,
      initial_price: null,
      model_name: "test 4 may17",
      suite_style: "3 Bed",
      increase_per_floor: null,
      exposure: ["N", "E"],
      bedroom: 3,
      bathroom: 3,
      den: false,
      study: false,
      tour_360: null,
      order: 6,
      floors: [],
      units: [],
      floor_plan: {
        id: 521,
        title: "test 4 may17",
        order: 1,
        image:
          "https://qoobox.qoo.studio/storage/uploads/66476e8be26b1_1d+d.png",
        thumbnail: null,
        file: null,
        link: null,
        for: "UnitModel",
      },
      key_plan: {
        id: 522,
        title: "test 4 may17",
        order: 1,
        image:
          "https://qoobox.qoo.studio/storage/uploads/66476ea02e0cb_1d+d.png",
        thumbnail: null,
        file: null,
        link: null,
        for: "UnitModel",
      },
      document: {
        id: 523,
        title: "test 4 may17",
        order: 1,
        image: null,
        thumbnail: null,
        file: "https://qoobox.qoo.studio/storage/uploads/66476ea4dd446_1D+D.pdf",
        link: null,
        for: "UnitModel",
      },
      gallery: [
        {
          id: 78,
          type: "floorplan",
          name: "floorplan",
          order: 1,
          category_item: [
            {
              id: 84,
              name: "Floor Plan",
              order: 1,
              galleries: [
                {
                  id: 506,
                  title: "model test 124",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/66471a53bc46a_494-G.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 509,
                  title: "test may17",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/664753e15ba7f_1a.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 518,
                  title: "test 3 may 17",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/66476e2ec1e90_1c+d.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 521,
                  title: "test 4 may17",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/66476e8be26b1_1d+d.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
              ],
            },
          ],
        },
        {
          id: 79,
          type: "keyplan",
          name: "keyplan",
          order: 1,
          category_item: [
            {
              id: 85,
              name: "Key Plan",
              order: 1,
              galleries: [
                {
                  id: 507,
                  title: "model test 124",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/66471a589877c_494-g.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 510,
                  title: "test may17",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/664753e70543d_1a.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 519,
                  title: "test 3 may 17",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/66476e32abe07_1c+d.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 522,
                  title: "test 4 may17",
                  order: 1,
                  image:
                    "https://qoobox.qoo.studio/storage/uploads/66476ea02e0cb_1d+d.png",
                  thumbnail: null,
                  file: null,
                  link: null,
                  for: "UnitModel",
                },
              ],
            },
          ],
        },
        {
          id: 80,
          type: "document",
          name: "document",
          order: 1,
          category_item: [
            {
              id: 86,
              name: "Document",
              order: 1,
              galleries: [
                {
                  id: 508,
                  title: "model test 124",
                  order: 1,
                  image: null,
                  thumbnail: null,
                  file: "https://qoobox.qoo.studio/storage/uploads/66471a8520284_video brochure-cover-02_Apr22.pdf",
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 511,
                  title: "test may17",
                  order: 1,
                  image: null,
                  thumbnail: null,
                  file: "https://qoobox.qoo.studio/storage/uploads/664753eac4e76_1A.pdf",
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 520,
                  title: "test 3 may 17",
                  order: 1,
                  image: null,
                  thumbnail: null,
                  file: "https://qoobox.qoo.studio/storage/uploads/66476e36eea93_1C+D.pdf",
                  link: null,
                  for: "UnitModel",
                },
                {
                  id: 523,
                  title: "test 4 may17",
                  order: 1,
                  image: null,
                  thumbnail: null,
                  file: "https://qoobox.qoo.studio/storage/uploads/66476ea4dd446_1D+D.pdf",
                  link: null,
                  for: "UnitModel",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 123,
      area: 1320,
      color: null,
      indoor_area: 1200,
      outdoor_area: 120,
      initial_price: 800000,
      model_name: "Model 123",
      suite_style: "3 Bed + Den",
      increase_per_floor: null,
      exposure: ["S", "E"],
      bedroom: 3,
      bathroom: 0,
      den: true,
      study: false,
      tour_360: "https://temp.qoo.studio/#/favourites",
      order: 12,
      floors: [],
      units: [],
      floor_plan: null,
      key_plan: null,
      document: null,
      gallery: [],
    },
  ];
  allData.value = data;
  setData(allData.value);

  setTimeout(() => {
    const imageHeight = Math.max(
      seqBgImage.value?.clientHeight,
      window.innerHeight || 0,
    );
    svgTopNumber.value = (pageHeight.value - imageHeight) / 2;
  }, 1000);

  setSvgPath();
});
</script>

<style lang="scss">
#image-sequence {
  height: calc(100vh - 95px);
  position: absolute;
  overflow: hidden;
  opacity: 0;

  &.show-opacity {
    opacity: 1;
    transition: 1s;
  }

  .container-image {
    position: relative;
  }

  .green {
    filter: invert(71%) sepia(20%) saturate(2702%) hue-rotate(92deg)
      brightness(93%) contrast(79%);
    -webkit-filter: invert(71%) sepia(20%) saturate(2702%) hue-rotate(92deg)
      brightness(93%) contrast(79%);
    opacity: 0.6;
  }

  .hide {
    display: none;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(1.2);
    }
  }

  @keyframes pulse-ring {
    0% {
      transform: scale(0);
      opacity: 1;
    }

    100% {
      transform: scale(1);
      opacity: 0;
    }
  }

  circle {
    &.dot {
      transform-origin: 50% 50%;
      animation: pulse 0.6s -0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) alternate
        infinite;
    }

    &.ring {
      transform-origin: 50% 50%;
      animation: pulse-ring 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }
  }

  .box-modal {
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(16px);
    position: fixed;

    &.h-500 {
      height: 500px;
    }

    &.h-400 {
      height: 400px;
    }

    .bg-white {
      img {
        max-height: 150px;
      }
    }

    .box-modal-header {
      border-color: rgba(255, 255, 255, 0.2);
    }
  }
}

@media screen and (max-width: 991px) {
  #image-sequence {
    height: calc(100vh - 75px);
    opacity: 1 !important;

    &.mobile-scale {
      .container-image {
        transform: scale(1.5);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #image-sequence {
    &.mobile-scale {
      .container-image {
        transform: scale(2.3);
      }
    }
  }
}

@media screen and (max-width: 460px) {
  #image-sequence {
    &.mobile-scale {
      .container-image {
        transform: scale(2.8);
        top: 130px !important;
      }
    }
  }
}
</style>
