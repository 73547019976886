let baseURL = "";
let loginBaseUrl = "";

if (process.env.VUE_APP_MODE === "development") {
  // Development mode
  baseURL = process.env.VUE_APP_BASE_URL;
  loginBaseUrl = process.env.VUE_APP_BASE_URL_LOGIN;
} else {
  // Production mode
  const currentUrl = window.location.href;
  if (currentUrl.startsWith("https://develop")) {
    // If the app is being served from a URL that starts with "develop"
    baseURL = process.env.VUE_APP_BASE_URL_DEV;
    loginBaseUrl = process.env.VUE_APP_BASE_URL_LOGIN_DEV;
  } else {
    // Otherwise use the production URL
    baseURL = process.env.VUE_APP_BASE_URL_PRO;
    loginBaseUrl = process.env.VUE_APP_BASE_URL_LOGIN_PRO;
  }
}

export { baseURL, loginBaseUrl };
