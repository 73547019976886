import { defineStore } from "pinia";

export const useModels = defineStore("models", {
  state: () => ({
    modelsDefaultList: [],
    modelsFilteredList: [],
  }),
  getters: {
    getModelsDefaultList(state) {
      return state.modelsDefaultList;
    },
    getModelsFilteredList(state) {
      return state.modelsFilteredList;
    },
  },
  actions: {
    setDefaultData(e) {
      this.modelsDefaultList = e;
    },
    setFilteredData(e) {
      this.modelsFilteredList = e;
    },
  },
});
