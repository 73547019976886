<template>
  <div class="main-container-scroll">
    <div
      class="scroll-container hide-scrollbar"
      :style="{ 'max-height': height }"
      @scroll="handleScroll"
      ref="scrollContainer"
    >
      <div class="scroll-content" ref="scrollContent">
        <slot></slot>
      </div>
    </div>
    <div
      class="row-scrollbar"
      v-show="showScrollbar"
      :style="{ top: top, right: right }"
    >
      <div
        class="scrollbar-container"
        :style="{ height: minH }"
        ref="scrollbarContainer"
      >
        <div
          class="scrollbar"
          ref="scrollbar"
          :style="{ height: scrollbarH }"
          @mousedown="startDrag"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { useElementSize } from "@vueuse/core";

function convertRemToPixels(rem) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

function convertVhToPixels(vh) {
  return window.innerHeight * (vh / 100);
}

function extractCalcValues(str) {
  const regex = /calc\((\d+)vh\s*[-+/*]\s*(\d+(?:\.\d+)?)\s*rem\)/;
  const match = str.match(regex);

  if (match) {
    const vh = convertVhToPixels(parseInt(match[1], 10));
    const val = convertRemToPixels(parseFloat(match[2]));
    return { vh, val };
  }

  return null;
}

function convertCALCToPixels(value) {
  const v = extractCalcValues(value);
  return v.vh - v.val;
}

export default {
  name: "CustomScrollbar",
  props: {
    h: {
      type: String,
      default: "calc(100vh - 12.75rem)",
    },
    minH: {
      type: String,
      default: "calc(100vh - 14rem)",
    },
    top: {
      type: String,
      default: "10px",
    },
    right: {
      type: String,
      default: "-10px",
    },
  },
  data() {
    return {
      startY: 0,
      height: "calc(100vh - 12.75rem)",
      minHeight: "calc(100vh - 14rem)",
      startTop: 0,
      showScrollbar: false,
      scrollbarH: "100px", // Initial value, will be recalculated
    };
  },
  mounted() {
    this.updateScrollbar();
    window.addEventListener("resize", this.updateScrollbar);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateScrollbar);
  },
  watch: {
    h: {
      handler() {
        this.updateScrollbar();
      },
    },
  },
  updated() {
    this.$nextTick(this.updateScrollbar);
  },
  methods: {
    updateScrollbar() {
      this.height = this.h;
      this.minHeight = this.minH;
      const heightInPixels = this.convertToPixels(
        this.h,
        this.$refs.scrollContainer,
      );
      const scrollContentHeight = this.$refs.scrollContent
        ? this.$refs.scrollContent.clientHeight
        : 0;
      this.showScrollbar = scrollContentHeight > heightInPixels;

      if (this.showScrollbar) {
        const containerHeight = this.$refs.scrollbarContainer.clientHeight;
        const scrollbarHeightRatio = heightInPixels / scrollContentHeight;
        this.scrollbarH = `${containerHeight * scrollbarHeightRatio}px`;
      } else {
        this.scrollbarH = "0px";
      }
    },
    handleScroll() {
      const scrollContainer = this.$refs.scrollContainer;
      const { scrollTop, clientHeight } = scrollContainer;
      const scrollContent = this.$refs.scrollContent;
      const scrollContentHeight = scrollContent.clientHeight;
      const scrollbarHeight = this.$refs.scrollbar.offsetHeight;
      const containerHeight = this.$refs.scrollbarContainer.clientHeight;

      const maxScrollTop = scrollContentHeight - clientHeight;
      const maxScrollbarTop = containerHeight - scrollbarHeight;

      if (maxScrollTop > 0) {
        const scrollPercentage = scrollTop / maxScrollTop;
        const newTop = scrollPercentage * maxScrollbarTop;
        this.$refs.scrollbar.style.top = `${newTop}px`;
      } else {
        this.$refs.scrollbar.style.top = "0px";
      }
    },
    startDrag(e) {
      this.startY = e.clientY;
      this.startTop = parseInt(
        window.getComputedStyle(this.$refs.scrollbar).top,
        10,
      );

      document.addEventListener("mousemove", this.onMouseMove);
      document.addEventListener("mouseup", this.stopDrag);
      e.preventDefault();
    },

    onMouseMove(e) {
      const deltaY = e.clientY - this.startY;
      // Ensure the scrollbar does not move outside the container
      const maxTop =
        this.$refs.scrollbarContainer.clientHeight -
        this.$refs.scrollbar.clientHeight;
      const newTop = Math.max(0, Math.min(maxTop, this.startTop + deltaY));

      this.$refs.scrollbar.style.top = `${newTop}px`;
      const scrollPercentage = newTop / maxTop;
      this.$refs.scrollContainer.scrollTop =
        scrollPercentage *
        (this.$refs.scrollContent.clientHeight -
          this.$refs.scrollContainer.clientHeight);
    },
    stopDrag() {
      document.removeEventListener("mousemove", this.onMouseMove);
      document.removeEventListener("mouseup", this.stopDrag);
    },
    convertToPixels(value, parentElement) {
      if (value.includes("calc")) {
        return convertCALCToPixels(value);
      } else if (value.includes("rem")) {
        return convertRemToPixels(parseFloat(value));
      } else if (value.includes("vh")) {
        return convertVhToPixels(parseFloat(value));
      } else if (value.includes("%")) {
        const { height } = useElementSize(parentElement);
        return (parseFloat(value) / 100) * height.value;
      } else {
        return parseFloat(value);
      }
    },
  },
};
</script>

<style scoped>
.main-container-scroll {
  position: relative;
}

.scroll-container {
  overflow: auto;
  position: relative;
}

.row-scrollbar {
  position: absolute;
  z-index: 15;
}

.scrollbar-container {
  position: relative;
  width: 3px;
  background: #d9d9d9;
  border-radius: 2px;
}

.scrollbar {
  position: absolute;
  width: 100%;
  background: #7a7a7a;
  border-radius: 2px;
  cursor: pointer;
}
</style>
